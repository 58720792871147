import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

// As you probably know, Gatsby makes it very easy to route the project.  You simply need to add the .js or .jsx file
// To the pages directory and then the name of the page becomes the route.  However, in order to organize the project
// Properly, you would typically put the .jsx file and the accompanying .scss file into it's own directory.  Doing
// That for the pages, would create a double directory for the route.  For example, /home/home.  As a result,
// For organization purposes, I have placed the pages here and simply returned the accompanying, "Content" jsx.
// The content jsx and scss are organized as they're supposed to be, within there own directory and that is the
// Where the content for each page really begins.

const Sitemap = () => {
  return (
    <Layout section="main" page="siteMap">
      <>
        <section className="bg-purple-010 py-16 md:py-24 px-6">
          <div className="container mx-auto py-1 md:px-0">
            <h1 className="font-mulish text-xxl font-black text-purple-100">
              Site Map
            </h1>
          </div>
        </section>
        <section className="px-6 pt-14">
          <div className="container xs:mx-auto md:pl-0">
            <h3 className="mb-4 text-md4 font-bold text-purple-100">
              Homepage
            </h3>
            <ul className="list-none pb-4 pl-0 text-md2 font-semibold text-purple-100">
              <li>
                <Link to="/">Myfembree Homepage</Link>
              </li>
              <li>
                <Link to="/starting-myfembree/">Starting Myfembree</Link>
              </li>
              <li>
                <Link to="/cost-and-support/">Cost & Savings</Link>
              </li>
              <li>
                <Link to="/cost-and-support/">FAQ</Link>
              </li>
            </ul>
            <h3 className="mb-4 text-md4 font-bold text-purple-100">
              Uterine Fibroids
            </h3>
            <ul className="list-none pb-4 pl-0 text-md2 font-semibold text-purple-100">
              <li>
                <Link to="/uterine-fibroids/">Uterine Fibroids Homepage</Link>
              </li>
              <li>
                <Link to="/uterine-fibroids/why-myfembree/">
                  Why Myfembree?
                </Link>
              </li>
              <li>
                <Link to="/uterine-fibroids/at-the-doctor/">
                  {' '}
                  Talking to Your Doctor
                </Link>
              </li>
              <li>
                <Link to="/uterine-fibroids/starting-myfembree/">
                  Starting Myfembree
                </Link>
              </li>
              <li>
                <Link to="/uterine-fibroids/cost-and-support/">
                  Cost & Savings
                </Link>
              </li>
              <li>
                <Link to="/uterine-fibroids/faq/">FAQ</Link>
              </li>
            </ul>
            <h3 className="mb-4 text-md4 font-bold text-purple-100">
              Endometriosis
            </h3>
            <ul className="list-none pl-0 text-md2 font-semibold text-purple-100 last-grouo">
              <li>
                <Link to="/endometriosis/">Endometriosis Homepage</Link>
              </li>
              <li>
                <Link to="/endometriosis/why-myfembree/">Why Myfembree?</Link>
              </li>
              <li>
                <Link to="/endometriosis/at-the-doctor/">
                  Talking to Your Doctor
                </Link>
              </li>
              <li>
                <Link to="/endometriosis/starting-myfembree/">
                  Starting Myfembree
                </Link>
              </li>
              <li>
                <Link to="/endometriosis/cost-and-support/">
                  Cost & Savings
                </Link>
              </li>
              <li>
                <Link to="/endometriosis/faq/">FAQ</Link>
              </li>
            </ul>
          </div>
        </section>
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL = 'https://www.myfembree.com/sitemap/';
  const title =
    'Sitemap | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate)';
  const pageTitle =
    'Sitemap | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate)';
  const description =
    'See a list of pages for the MYFEMBREE® patient website.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};
export default Sitemap;
